<template>
  <div></div>
</template>

<script>
export default {
  name: "westudyOnline",
  beforeMount() {
    this.$router.push(this.localeLink(''))
  }
}
</script>

<style scoped>

</style>